import MomentUtils from '@date-io/moment';
import {
  ButtonBase,
  Typography
} from '@material-ui/core';
import { TodayOutlined } from '@material-ui/icons';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import withStyles from '@material-ui/styles/withStyles';
import percent from 'assets/svg/percent.svg';
import axios from 'axios';
import * as moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'UI/button';
import { baseURL } from 'utils/apiEndpoints';
import { PROMO_CASH_LIMIT, TIMEOUT } from 'utils/consts';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as _ from 'lodash';

class AddPromoCash extends Component {
  constructor(props) {
    super(props);
    const {
      walletBalance,
    } = props;
    this.state = {
      walletBalance,
      expiryDate: moment().utcOffset('+0530').endOf('day').toISOString(),
    };
  }

  addPromoCash = async () => {
    const { showSnackbar, userId, requestId } = this.props;
    const { expiryDate } = this.state;
    const url = `${baseURL}wallets/addPromoMoney`;
    axios({
      method: 'post',
      url,
      headers: this.headers,
      timeout: TIMEOUT,
      data: {
        userId,
        requestId,
        creditTxnValidUpto: expiryDate,
      },
    }).then((response) => {
      const newWalletBalance = response?.data?.wallet?.balance || 0;
      this.setState({ walletBalance: newWalletBalance });
      if (newWalletBalance > 0) {
        showSnackbar('Promo cash added successfully.', 'success');
      } else {
        showSnackbar(`Customer not eligible. ${response.data?.reason || ''}`, 'error');
      }
    }).catch(() => {
      showSnackbar('Something went wrong, please try again later', 'error');
    });
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      expiryDate,
      walletBalance,
    } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <AccountBalanceWalletIcon
            style={{ marginRight: 10 }}
          />
          Promotional Cash
        </div>
        <div className={classes.body}>
          <div className={classes.balanceContainer}>
            <div style={{ fontSize: 18, fontWeight: 'medium', marginRight: 10 }}>
              Promo Cash Balance:
            </div>
            <div className={classes.balance}>
              ₹{_.round(walletBalance/100, 2)}
            </div>
          </div>
          <div className={classes.addPromoCashContainer}>
            <div style={{ flex: 1, paddingTop: 8 }}>
              <img src={percent} alt="[]" />
            </div>
            <div style={{
              flex: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
            }}
            >
              <div style={{ fontSize: 20 }}>
                Add Promotional Cash
              </div>
              <div style={{ fontSize: 24, fontWeight: 'bold' }}>
                ₹12,000
              </div>
              <div style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
              >
                <div>
                  <div style={{ fontSize: 14, fontWeight: 'medium', paddingTop: 10 }}>
                    Choose Expiry Date
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ButtonBase
                      type="button"
                      key="expiryDate"
                      onClick={() => {}}
                    >
                      <span className={classes.calendarIcon}>
                        <TodayOutlined />
                      </span>
                      <DatePicker
                        style={{ width: 100 }}
                        disablePast
                        format="D MMM YYYY"
                        value={expiryDate}
                        maxDate={
                          moment()
                            .utcOffset('+0530')
                            .add(10, 'day')
                            .endOf('day')
                            .toISOString()
                        }
                        onChange={(newExpiryDate) => {
                          console.log('newExpiryDate', newExpiryDate);
                          this.setState({ expiryDate: moment(newExpiryDate).utcOffset('+0530').endOf('day').toISOString() });
                        }}
                      />
                    </ButtonBase>
                  </MuiPickersUtilsProvider>
                </div>
                <Button
                  disabled={walletBalance > 0}
                  className={classes.addButton}
                  onClick={() => {
                    const confirmed = window.confirm('Are you sure you want to add the promo cash ?');
                    if (confirmed) {
                      this.addPromoCash();
                    }
                  }}
                >
                  Add ₹12,000 Cash
                </Button>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InfoOutlinedIcon style={{ fontSize: 16 }} /> <span style={{ marginLeft: 5 }} />
              <Typography sx={classes.noteTitle}>Please Note</Typography>
            </div>
            <ul className={classes.noteList}>
              <li>Set a short expiry window to push clients to close deal immediately. If unused, <strong>promo cash expires</strong></li>
              <li>Customer can use promo cash to get discounts <strong>up to ₹{PROMO_CASH_LIMIT.toLocaleString()} </strong> on each trip</li>
              <li>If the promo cash is used, the expiry for the unused amount will be <strong>extended for 1 year</strong></li>
              <li>Amount will be <strong>deducted from the total margins</strong> set on the deal and will reduce your earnings</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

AddPromoCash.propTypes = {
  classes: PropTypes.object,
  walletBalance: PropTypes.object,
  userId: PropTypes.string,
  showSnackbar: PropTypes.func,
  requestId: PropTypes.string,
};

const styles = (theme) => ({
  container: {
    minWidth: 600,
    maxHeight: '90vh',
    overflowY: 'auto',
    height: 'auto',
    backgroundColor: theme.colors.white,
    border: '1px solid #E0E0E0',
    borderRadius: 10,
  },
  header: {
    borderBottom: `1px solid ${theme.colors.grey}`,
    padding: '15px 60px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    '& svg': {
      color: theme.colors.primary,
    },
  },
  body: {
    padding: '15px 60px',
    fontFamily: 'Lato',
  },
  calendarIcon: {
    marginTop: 8,
    marginRight: 5,
    color: theme.colors.primary,
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '60%',
    alignItems: 'center',
  },
  balance: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  addPromoCashContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 30,
    padding: 30,
    border: `1px solid ${theme.colors.primary}`,
    borderRadius: 10,
  },
  title: { fontWeight: 600 },
  closeButton: { color: 'gray' },
  balanceTitle: { color: 'gray', marginBottom: '5px' },
  balanceAmount: { fontWeight: 700 },
  card: {
    background: '#f9f9f9',
    padding: '15px',
    borderRadius: '10px',
    marginTop: '20px',
    textAlign: 'center',
  },
  expiryLabel: {
    marginTop: '10px',
    fontSize: '14px',
    color: 'gray',
  },
  select: {
    width: '100%',
    marginTop: '5px',
  },
  addButton: {
    marginTop: '15px',
    minWidth: 140,
    padding: '4px 12px',
    borderRadius: 25,
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  noteTitle: {
    fontWeight: 600,
    marginTop: '20px',
  },
  noteList: {
    paddingLeft: '20px',
    fontSize: '14px',
    color: 'gray',
  },
});

export default withStyles(styles)(AddPromoCash);
